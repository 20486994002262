import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueRouter from "vue-router";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import vuetify from "@/plugins/vuetify";
import "font-awesome/css/font-awesome.css";
import "./assets/css/style.css";
import Notifications from "vue-notification";
import VueClipboard from "vue-clipboard2";
import Vue2Editor from "vue2-editor";
Vue.component("notify", require("@/components/shared/notify.vue").default);
Vue.component("vTable", require("@/components/shared/vue-table.vue").default);
import util from "@/util/util.js";
Vue.prototype.$http = util.http;

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Notifications);
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

let app = new Vue({
  vuetify,
  router,
  Vue2Editor,
  store,
  render: (h) => h(App),
}).$mount("#app");
export default app;
