import Vue from "vue";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
// import { util } from "geeklearners-vue-table";
var Url = require("url-parse");
import app from "@/main";
var url = new Url(window.location.href, true);
// let subdomain = url.hostname.split(".")[0];
let b = "https://caliberbuilders-golocrm.geniusits.com/api/";
// let b = "http://genius.localhost:8000/api/";
// let b = (function() {
//   if (window.location.protocol == "https:") {
//     return `https://${subdomain}-api.golocrm.com/api/`;
//   } else {
//     return `http://${subdomain}.localhost:8000/api/`;
//   }
// })();
if (url.query.token) {
  axios.defaults.headers.common["Authorization"] = url.query.token;
  sessionStorage.setItem("token", url.query.token);
}
console.log(url);

export default {
  baseUrl: b,
  http: (() => {
    let token = sessionStorage.getItem("token") || "";
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`.replace(/"/, ""),
      },
      baseURL: b,
    });
    instance.interceptors.request.use(
      function(config) {
        let token = sessionStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`.replace(/"/, "");
        }
        return config;
      },
      function(error) {
        // app.$store.commit("saveIsLoading", false);
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (resp) => {
        return resp;
      },
      (err) => {
        if (err.response.status == 401) {
          sessionStorage.removeItem("token");
          app.$router.replace("/login");
        } else {
          // app.handleException.call(app, err);
          return Promise.reject(err);
        }
      }
    );
    return instance;
  })(),

  event: new Vue(),
  loader: (value) =>
    value == 0
      ? (app.$store.state.loader = false)
      : (app.$store.state.loader = true),
  loginUsingToken: (token) => sessionStorage.setItem("token", token),
  camelCase: (val) => _.startCase(_.toLower(val)),
  upperCase: (val) => _.upperCase(val),
  notify: (status, message) => {
    Vue.notify({
      group: "custom-template",
      title: "Alert !",
      text: message,
      type: status == 1 ? "success" : "error",
    });
  },
  emptyObject: function(obj) {
    for (let k in obj) {
      obj[k] = "";
    }
  },
  validEmail: function(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  validNumber: function(number) {
    var re = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[4])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
    return re.test(number);
  },
  priorityOptions: ["High", "Medium", "Low"],
  extractErrorsToArray: (errors) => {
    let e = [];
    for (let k in errors) {
      e = [...e, ...errors[k]];
    }
    return e;
  },
  changeDateFormat: function(d) {
    if (d != null && d != "") {
      return moment(d).format("Do MMM YYYY");
    }
    return "";
  },
  changeTimeFormat: function(d) {
    if (d != null && d != "") {
      return moment(d).format("hh:mm A");
    }
    return "";
  },
  validation: {
    required: (value) => !!value || "Required.",
    number: (value) => !isNaN(value) || "Must be a Number",
    postalLength: (value) => value > 999 || "Invalid Postal Code",
    email: (value) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,4}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
    validPhoneNumber: (value) => {
      const pattern = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[4])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
      return pattern.test(value) || "Invalid Phone Number";
    },
  },
  fetchFiles(id) {
    axios({
      url:
        "https://graph.microsoft.com/v1.0/me/drive/root/" +
        id +
        "?$expand=thumbnails",
    });
  },
  fetchPermissions() {
    this.$http({
      url: "/core/role/permissions/user-permissions",
      method: "get",
    }).then((res) => {
      this.$store.commit("setPermissions", res.data.data);
    });
  },
  CHUNK_SIZE: {
    ONEDRIVE: 983040 * 5,
    DROPBOX: 5242880 * 5,
    GOOGLEDRIVE: 4194304 / 2,
  },
  input: {
    basic: {
      dense: true,
      outlined: true,
      "hide-details": "auto",
    },
  },
  constructTree(data) {
    let children_holder = ["boards", "tasks", "sub_tasks"];
    for (let obj of data) {
      if ("title" in obj) {
        obj.name = obj.title;
      }
      for (let k in obj) {
        if (Array.isArray(obj[k]) && children_holder.includes(k)) {
          obj["children"] = this.constructTree(obj[k]);
        }
      }
    }
    return data;
  },
  getColor(val) {
    if (val == "Paid") {
      return "green";
    } else if (val == "Partially Paid") {
      return "warning";
    } else if (val == "Unpaid") {
      return "error";
    }
  },
  total(q, p) {
    let total = q * p;
    return total.toFixed(2);
  },
};
