import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    permissions: [],
    encrypted_email: "",
    loader: false,
    drive_token: localStorage.getItem("drive_token") || null,
    one_drive_token: localStorage.getItem("one_drive_token") || null,
    dropbox_token: localStorage.getItem("dropbox_token") || null,
    stack: [],
    showOneDriveShareDialog: false,
    showGoogleDriveShareDialog: false,
    showDropboxShareDialog: false,
    showRenameDialog: false,
    basicSetUpData: {},
  },
  mutations: {
    setPermissions(state, data) {
      return (state.permissions = data);
    },
    setEmail(state, data) {
      return (state.encrypted_email = data);
    },
    setDriveToken(state, data) {
      state.drive_token = data;
    },
    setOneDriveToken(state, data) {
      state.one_drive_token = data;
    },
    setDropboxToken(state, data) {
      state.dropbox_token = data;
    },
    addToStack(state, data) {
      let s = state.stack.findIndex((item) => item.id == data.id);
      if (s == -1) {
        state.stack.push(data);
      } else {
        state.stack.splice(s + 1);
      }
    },
    toggleOneDriveShareDialog(state, data) {
      state.showOneDriveShareDialog = data;
    },
    toggleGoogleDriveShareDialog(state, data) {
      state.showGoogleDriveShareDialog = data;
    },
    toggleDropboxShareDialog(state, data) {
      state.showDropboxShareDialog = data;
    },
    toggleRenameDialog(state, data) {
      state.showRenameDialog = data;
    },
    setBasicSetUpData(state, data) {
      state.basicSetUpData = data;
    },
  },
  actions: {},
  modules: {},
});
