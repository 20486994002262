import Vue from "vue";
import VueRouter from "vue-router";
const Home = () => import("../views/Home.vue");
const Dashboard = () => import("../views/Dashboard.vue");
const Contacts = () => import("../views/Contacts.vue");
const Events = () => import("../views/Events.vue");
const EventList = () => import("@/components/events/list.vue");
const Notification = () => import("../views/Notification.vue");
const TaskManagement = () => import("../views/TaskManagement.vue");
const Reports = () => import("../views/Reports.vue");
const Files = () => import("../views/Files.vue");
const AddContact = () => import("../components/Contact/AddContact.vue");
const ContactView = () => import("../components/Contact/ContactView.vue");
const Board = () => import("../views/Board.vue");
// const CreateBoard =import("../views/CreateBoard.vue");
const EditContact = () => import("../components/Contact/EditContact.vue");
const Login = () => import("../components/Auth/Login");
const Register = () => import("../components/Auth/Register");
const OTPPage = () => import("../components/Auth/OTPPage");
const Template = () =>
  import("../components/Quotation/template/quotation1.vue");

const CompanySettingList = () =>
  import("../components/CompanySetting/ListCompanySetting");
const CompanySettingEdit = () =>
  import("../components/CompanySetting/EditCompanySetting");
const ProjectDetail = () => import("../components/Project/ProjectDetail.vue");
const CreateProject = () => import("../components/Project/CreateProject.vue");
const ContactTable = () => import("../components/Contact/List.vue");
const AdditionalInformation = () =>
  import(
    "../components/Contact/PersonalContactsInfos/AdditionalInformation.vue"
  );
const ContactNotify = () =>
  import("../components/Contact/PersonalContactsInfos/ContactNotify.vue");
const ContactNotes = () =>
  import("../components/Contact/PersonalContactsInfos/ContactNotes.vue");
const ContactTags = () =>
  import("../components/Contact/PersonalContactsInfos/Tags.vue");
const AddEvent = () => import("../components/events/create.vue");
const google = () => import("@/components/File/GoogleDrive/google-wrapper");
const onedrive = () => import("@/components/File/one-drive");
// const dropbox =import("@/components/File/dropbox";
const dropbox = () => import("@/components/File/Dropbox/dropbox-wrapper");

/**
 * Quotation part
 */
const CreateQuotation = () => import("@/components/Quotation/create");
const ListQuotation = () => import("@/components/Quotation");
const print = () => import("@/components/shared/print");

//gantt
const GanttChart = () => import("@/components/Project/gantt-wrapper");

//roles
// const RoleList =() =>import("@/components/Role/index");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "/",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/contacts/add",
        name: "AddContact",
        component: AddContact,
      },
      {
        path: "/contacts",
        name: "Contacts",
        component: Contacts,
        children: [
          {
            path: ":id",
            name: "single-contact",
            component: ContactView,
            children: [
              {
                path: "additional-information",
                name: "AdditionalInformation",
                component: AdditionalInformation,
              },
              {
                path: "notify",
                name: "ContactNotify",
                component: ContactNotify,
              },
              {
                path: "notes",
                name: "ContactNotes",
                component: ContactNotes,
              },
              {
                path: "tags",
                name: "ContactTags",
                component: ContactTags,
              },
            ],
          },

          {
            path: "/contacts/:id/edit",
            name: "EditContact",
            component: EditContact,
          },
        ],
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      {
        path: "/contacts-list",
        name: "ContactTable",
        component: ContactTable,
      },
      {
        path: "/events",
        name: "Events",
        component: Events,
      },
      {
        path: "/events/list",
        name: "EventList",
        component: EventList,
      },
      {
        path: "/events/add",
        name: "AddEvent",
        component: AddEvent,
      },
      {
        path: "/notification",
        name: "Notification",
        component: Notification,
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
      },
      {
        path: "/task/board",
        name: "Board",
        component: Board,
      },
      // {
      //   path: "/task/create-board",
      //   name: "CreateBoard",
      //   component: CreateBoard,
      // },
      {
        path: "task/project/:id/gantt-chart",
        name: "chart",
        component: GanttChart,
      },
      {
        path: "/task",
        name: "TaskManagement",
        component: TaskManagement,
        children: [
          {
            path: "new-project",
            name: "CreateProject",
            component: CreateProject,
          },
          {
            path: "project/:id/edit",
            name: "EditProject",
            component: CreateProject,
          },
          {
            path: "project/:id",
            name: "ProjectDetail",
            component: ProjectDetail,
          },
        ],
      },
      {
        path: "/files",
        component: Files,
        children: [
          {
            path: "",
            name: "google",
            component: google,
          },
          {
            path: "google",
            name: "google",
            component: google,
          },
          {
            path: "onedrive",
            name: "onedrive",
            component: onedrive,
          },
          {
            path: "dropbox",
            name: "dropbox",
            component: dropbox,
          },
        ],
      },
      {
        path: "/company-setting",
        name: "CompanySettingList",
        component: CompanySettingList,
      },
      {
        path: "/company-setting/edit/:id",
        name: "CompanySettingEdit",
        component: CompanySettingEdit,
      },
      {
        path: "/quotation/create",
        name: "quotation-create",
        component: CreateQuotation,
      },
      {
        path: "/quotation",
        name: "quotation",
        component: ListQuotation,
      },
      {
        path: "/template",
        name: "template",
        component: Template,
      },
      {
        path: "/quotation/:id/edit",
        name: "quotation-edit",
        component: CreateQuotation,
      },
      {
        path: "/jobs",
        name: "jobs",
        component: () => import("@/components/Jobs/index.vue"),
      },
      {
        path: "/jobs/detail/:id",
        name: "jobs.detail",
        component: () => import("@/components/Jobs/JobDetail/index.vue"),
      },
      {
        path: "/jobs/grid-view",
        name: "jobs.grid-view",
        component: () => import("@/components/Jobs/grid-view.vue"),
      },
      {
        path: "/job/create",
        name: "job.create",
        component: () => import("@/components/Jobs/create.vue"),
      },
      {
        path: "/job/:id/edit",
        name: "job.edit",
        component: () => import("@/components/Jobs/edit.vue"),
      },
      {
        path: "/job/priority",
        name: "job.priority",
        component: () => import("@/components/Jobs/JobPriorities/index.vue"),
      },
      {
        path: "/job/type",
        name: "job.type",
        component: () => import("@/components/Jobs/JobTypes/index.vue"),
      },
      {
        path: "/job/status",
        name: "job.status",
        component: () => import("@/components/Jobs/JobStatus/index.vue"),
      },
      {
        path: "/po",
        name: "po",
        component: () => import("@/components/PO/index.vue"),
      },
      {
        path: "/po/create",
        name: "po-create",
        component: CreateQuotation,
        props: {
          type: "po",
        },
      },
      {
        path: "/po/:id/edit",
        name: "po-edit",
        component: CreateQuotation,
        props: {
          type: "po",
        },
      },
      {
        path: "/invoice",
        name: "invoice",
        component: () => import("@/components/Invoice/index.vue"),
      },
      {
        path: "/invoice/:id/add-payment/",
        name: "invoice.payment.add",
        component: () => import("@/components/Invoice/payment/create.vue"),
      },
      {
        path: "/invoice/:id/payment",
        name: "invoice.payment.index",
        component: () => import("@/components/Invoice/payment/index.vue"),
      },
      {
        path: "/invoice/payment/:id/edit",
        name: "invoice.payment.edit",
        component: () => import("@/components/Invoice/payment/edit.vue"),
      },
      {
        path: "/invoice/create",
        name: "invoice-create",
        component: () => import("@/components/Invoice/create.vue"),
      },
      {
        path: "/invoice/:id/detail",
        name: "invoice-detail",
        component: () => import("@/components/Invoice/invoice-detail.vue"),
      },
      {
        path: "/invoice/:id/edit",
        name: "invoice-edit",
        component: () => import("@/components/Invoice/create.vue"),
      },
      {
        path: "/roles",
        name: "role-list",
        component: require("@/components/Role/index").default,
      },
      {
        path: "/roles/:id/assign",
        name: "AssignPermission",
        component: require("@/components/Role/assign").default,
      },
      {
        path: "/users",
        name: "user-list",
        component: require("@/components/User/index").default,
      },
      {
        path: "/users/:id/assign",
        name: "AssignRole",
        component: require("@/components/User/assign").default,
      },

      //products
      {
        path: "/product",
        name: "ProductList",
        component: require("@/components/Product/index").default,
      },

      {
        path: "/logs",
        name: "user-logs",
        component: require("@/components/Log/index").default,
      },

      {
        path: "/document",
        name: "DocumentList",
        component: () => import("@/components/Document/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/otp-page",
    name: "OTPPage",
    component: OTPPage,
    // meta: {auth:false},
  },
  {
    path: "/:item/print/:id",
    name: "print-file",
    component: print,
  },
  {
    path: "/:item/:approve/:id",
    name: "approve-quotation",
    component: print,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let allowedPath = ["Login", "OTPPage", "Register", "approve-quotation"];
router.beforeEach((to, from, next) => {
  let isAuthenticated = sessionStorage.getItem("token");
  if (allowedPath.indexOf(to.name) != -1 && isAuthenticated)
    next({ name: "dashboard" });
  // if (allowedPath.indexOf(to.name) == -1 && !isAuthenticated && !to.meta.auth)
  //     next({ name: "OTPPage" });

  if (allowedPath.indexOf(to.name) == -1 && !isAuthenticated)
    next({ name: "Login" });
  if (to.name == "OTPPage") {
    next();
  } else next();
});

export default router;
